<template>
  <div class="single-bridge-common">
    <div class="tit-search flex space-between">
      <div class="mb-2">
        <el-button class="btn-primary" @click="goto()">新增</el-button>
      </div>
      <c-search
          v-model="pageParams"
          :searchItems="searchItems"
          @search="pageGet(true)"
      ></c-search>
    </div>
    <div class="table-wrapper" >
      <c-table
          :index="false"
          :data="pageData"
          :tableColumns="tableColumns"
          :stripe="true"
          :pagination="pageParams"
          @change="pageGet"
      >
        <template v-slot:custom="{ row }">
          <span class="opt-update" style="color:#F4BB4E" @click="goto(row)">修改</span>
          <span class="opt-delete" @click="pageDel(row.id)">删除</span>
        </template>
      </c-table>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        :visible.sync="dialogVisible"
        :before-close="beforeDialogClose"
        :destroy-on-close="true"
        width="900px"
    >
      <div class="form-wrapper manage-scroll">
        <el-form ref="form" :model="form.model" :rules="form.rules" label-width="120px">
          <el-row :gutter="20" class="w-full">
            <el-col :span="12">
              <el-form-item label="测点" prop="pointList">
                <el-cascader
                    ref="tree"
                    :options="sensorList"
                    :props="props"
                    collapse-tags
                    :show-all-levels="false"
                    v-model="form.model.pointList"
                    clearable></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="超限级别" prop="checkWarnLevel">
                <el-select v-model="form.model.checkWarnLevel">
                  <el-option label="一级" :value="1"></el-option>
                  <el-option label="二级" :value="2"></el-option>
                  <el-option label="三级" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检查内容" prop="checkContent">
                <el-input type="textarea" v-model="form.model.checkContent"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检查建议" prop="checkGuide">
                <el-input type="textarea" v-model="form.model.checkGuide"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFields">取 消</el-button>
        <el-button class="btn-primary" @click="submit('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import MPage from "@/mixins/MPage.js";
  import Configs from "./config/index";
  let mPage = MPage({
    pagePath: "/check/guide/listPage",
    pageDelPath: "/check/guide/remove",
    pageDelsPath: "",
    pageParams: {},
  });
  export default {
    mixins: [mPage],
    data() {
      let { searchItems,tableColumns } = Configs(this);
      return {
        searchItems,
        tableColumns,
        dialogVisible: false,
        title:'',
        pageData:[],
        form:{
          model:{},
          rules:{
            checkWarnLevel:[
              { required: true, message: '请选择超限等级', trigger: 'blur' }
            ],
            checkContent:[
              { required: true, message: '请填写检查内容', trigger: 'blur' }
            ],
            checkGuide:[
              { required: true, message: '请填写检查建议', trigger: 'blur' }
            ]
          }
        },
        sensorList:[],
        props:{
          multiple: true,
          label:'name',
          value:'id',
          children:'sensorDetailList'
        },
        sensorDetailList:[],
        isAdd:true,
        api:''
      };

    },
    created() {
      this.getSensor()
    },
    methods: {
      getSensor(){
        this.$http.get("/check/guide/sensorList").then(res => {
          if(res.success){
            if (res.data){
              this.sensorList = []
              for (let item of res.data){
                if (item.sensorDetailList){
                  this.sensorList.push(item)
                }
              }
            }
          }
        })
      },
      getDetail(data){
        this.$http.get("/check/guide/detail",{params:{id:data.id}}).then(res => {
          if(res.success){
            if (res.data){
              let arr = []
              if (res.data.pointList && res.data.pointList.length > 0){
                for (let item of res.data.pointList){
                  for (let parent of this.sensorList){
                    for (let child of parent.sensorDetailList){
                      if (child.id == item){
                        arr.push([parent.id,child.id])
                      }
                    }
                  }
                }
              }
              this.form.model = res.data
              this.form.model.pointList = arr
              this.dialogVisible = true
            }
          }
        })
      },
      goto(data) {
        this.getSensor()
        this.form.model = {
          checkContent:'',
          checkWarnLevel:'',
          checkGuide:'',
          pointList:[]
        }
        if (data){
          this.title = "修改检查指引";
          this.isAdd = false
          this.api = '/check/guide/update'
          this.getDetail(data)
          // this.dialogVisible = true;
        } else {
          this.title = "新增检查指引";
          this.isAdd = true
          this.api = '/check/guide/save'
          this.dialogVisible = true;
        }
      },
      beforeDialogClose(done) {
        done();
      },
      submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let arr = []
            if (this.form.model.pointList.length > 0){
              this.form.model.pointList.forEach(item => {
                arr.push(item[1])
              })
            }
            this.form.model.pointList = arr
            this.$http.post(this.api,this.form.model).then(res=>{
              if(res.success){
                this.$message.success("操作成功");
                this.pageGet()
                this.dialogVisible = false;
              } else {
                this.$message.error(res.msg)
              }
            });
          }
        })
      },
      resetFields() {
        this.dialogVisible = false;
      },
    },
  };
</script>

<style scoped>
  .form-flex{
    display: flex;
  }
  .form-item-btn{
    width: 100px;
    margin-left: 10px;
  }
  .opt-update{
    margin-right: 10px;
  }
  .opt-update{
    cursor: pointer;
  }
</style>