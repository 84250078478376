import store from '@/store/index.js'
let dic = store.state.dic;
export default function Configs(that) {
  const searchItems = [
    {
      label: "超限等级",
      span: 12,
      model: "level",
      clearable: true,
      type: "select",
      options: [
        {label:'一级',value:1},
        {label:'二级',value:2},
        {label:'三级',value:3}
      ]
    },
    {
      label: "检查内容",
      span:12,
      type: "input",
      model: "content",
      clearable: true,
    },
  ];
  const tableColumns = [
    {
      label: "检查内容",
      prop: "checkContent",
      align: "center",
      show: true
    },
    {
      label: "超限等级",
      prop: "checkWarnLevel",
      align: "center",
      show: true
    },
    {
      label: "检查建议",
      prop: "checkGuide",
      align: "center",
      show: true
    },
    {
      label: "操作",
      name: 'custom',
      align: "center",
      show: true
    },
  ];
  return {
    searchItems,
    tableColumns
  };
}
